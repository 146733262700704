var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-chip',_vm._b({staticClass:"mb-3 mr-2",attrs:{"color":_vm.getColor(_vm.implementationStatus),"disabled":_vm.disabled},on:{"click:close":function($event){_vm.showClose && _vm.$emit('click:close')},"click":function($event){_vm.dialogValue = !_vm.dialogValue}}},'v-chip',{ close: _vm.showClose },false),[_c('v-avatar',{attrs:{"left":""}},[_c('tooltip',{attrs:{"top":"","text":_vm.tooltipText},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',slotProps.attrs,false),slotProps.on),[_vm._v("\n        "+_vm._s(_vm.getIcon(_vm.implementationStatus))+"\n      ")])]}}])})],1),_vm._v(" "),(_vm.isMaterial)?_c('v-avatar',{attrs:{"left":""}},[_c('tooltip',{attrs:{"top":"","text":_vm.$t('materialMeasure')},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',slotProps.attrs,false),slotProps.on),[_vm._v("mdi-flag")])]}}],null,false,325786142)})],1):_vm._e(),_vm._v(" "),(_vm.isMaterial)?_c('v-tooltip',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"info"}},'span',attrs,false),on),[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")])]}}],null,false,2872577473)},[_vm._v(" "),_c('div',{staticStyle:{"max-width":"350px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.renderMarkdown(
            '<p>' +
              _vm.$t('materialMeasureAttentionTooltipHeader') +
              '</p>' +
              _vm.materialityExplanation,
          )
        )}})])]):_c('span',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.dialogValue)?_c('ix-dialog',{attrs:{"value":_vm.dialogValue,"max-width":1000},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.showClose)?_c('div',{staticClass:"d-flex align-center"},[(!_vm.editing)?_c('span',{staticClass:"mr-2",on:{"click":function($event){_vm.editing = true}}},[_vm._v(_vm._s(_vm.label))]):_c('v-text-field',{attrs:{"dense":"","flat":"","solo":"","hide-details":""},on:{"blur":_vm.finishEditing,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.finishEditing.apply(null, arguments)}},model:{value:(_vm.editableLabel),callback:function ($$v) {_vm.editableLabel=$$v},expression:"editableLabel"}}),_vm._v(" "),(!_vm.editing)?_c('v-icon',{on:{"click":_vm.startEditing}},[_vm._v("mdi-pencil")]):_vm._e()],1):_c('span',[_vm._v("\n        "+_vm._s(_vm.label)+" "),(_vm.isMaterial)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-flag")]):_vm._e()],1)]},proxy:true},{key:"text",fn:function(){return [(_vm.isMaterial)?_c('v-alert',{staticClass:"mb-8",attrs:{"outlined":"","type":"info","color":"primary"}},[_c('span',{staticStyle:{"color":"black"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              '<p>' +
                _vm.$t('materialMeasureAttentionTooltipHeader') +
                '</p>' +
                _vm.materialityExplanation,
            )
          )}})]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('description')))]),_vm._v(" "),_c('span',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.description))}})]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('howAddressingMeasure')))]),_vm._v("\n        "+_vm._s(_vm.$t('actionsTaken'))+"\n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('esg-actions-table',{attrs:{"parent-concept":_vm.concept,"user-input-id":_vm.userInput.id,"actions-editable":_vm.actionsEditable,"questionnaire-id":_vm.questionnaireId}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.comment,"disabled":!_vm.actionsEditable,"label":_vm.$t('commentLabel')},on:{"input":(val) => _vm.$emit('input', { comment: val, concept: _vm.concept })}})]},proxy:true}],null,false,528606263)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }