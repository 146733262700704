import { render, staticRenderFns } from "./ChipWithSubmeasures.vue?vue&type=template&id=b1415062"
import script from "./ChipWithSubmeasures.vue?vue&type=script&lang=ts"
export * from "./ChipWithSubmeasures.vue?vue&type=script&lang=ts"
import style0 from "./ChipWithSubmeasures.vue?vue&type=style&index=0&id=b1415062&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ChipWithSubmeasures.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EsgActionsTable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/ActionsTable.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAvatar,VChip,VIcon,VTextField,VTooltip})
