import "core-js/modules/es6.number.constructor.js";
import Tooltip from '~/components/ix/info/Tooltip.vue';
import { getColor, getIcon, getTooltip } from '~/functions/esg';
import { renderMarkdown } from '~/functions/markdown';
import { ImplementationStatus } from '~/types/types';
export default defineComponent({
  components: {
    Tooltip: Tooltip
  },
  props: {
    concept: {
      type: Object,
      required: true
    },
    userInput: {
      type: Object,
      required: true
    },
    comment: {
      type: String,
      required: true
    },
    implementationStatus: {
      type: String,
      default: ImplementationStatus.NotPlanned
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    actionsEditable: {
      type: Boolean,
      default: true
    },
    materialityExplanation: {
      type: String,
      default: '',
      required: false
    },
    isMaterial: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var dialogValue = ref(false);
    var editing = ref(false);
    var editableLabel = ref(props.label);
    var startEditing = function startEditing() {
      editing.value = true;
    };
    var finishEditing = function finishEditing() {
      editing.value = false;
      emit('update-label', editableLabel.value);
    };
    var getEvidenceItem = function getEvidenceItem(citation, url) {
      return {
        comment: citation !== null && citation !== void 0 ? citation : '',
        url: url !== null && url !== void 0 ? url : ''
      };
    };
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var tooltipText = computed(function () {
      return $i18n.t(getTooltip(props.implementationStatus));
    });
    return {
      dialogValue: dialogValue,
      getColor: getColor,
      getIcon: getIcon,
      renderMarkdown: renderMarkdown,
      getEvidenceItem: getEvidenceItem,
      tooltipText: tooltipText,
      ImplementationStatus: ImplementationStatus,
      editing: editing,
      editableLabel: editableLabel,
      startEditing: startEditing,
      finishEditing: finishEditing
    };
  }
});